// External Dependencies
import React from 'react';

class AboutAuthor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      author: [],
    };
  }
  componentDidMount() {
    fetch(
      `/wp-json/wp/v2/users/${window.ETBuilderBackend.postMeta.post_author}`,
      {
        method: 'GET',
      }
    )
      .then((response) => {
        if (response.ok && response.status === 200) {
          return response.json();
        }
        throw new Error(response.statusText);
      })
      .then((response) => {
        this.setState({
          author: response,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error); //eslint-disable-line
        }
      });
  }
  render() {
    return this.state.author ? (
      <div id="authorarea">
        <p className="author__title">About the Author</p>
        <h6 className="author__name">{this.state.author.name}</h6>
        <div className="author__links">
          <a href="#">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="#">
            <i className="fas fa-phone-square-alt"></i>
          </a>
          <a href="#">
            <i className="fas fa-envelope-square"></i>
          </a>
        </div>
        <div
          className="author__info"
          dangerouslySetInnerHTML={{ __html: this.state.author.description }}
        />
      </div>
    ) : (
      ''
    );
  }
}

AboutAuthor.slug = 'fwp_about_author';

export default AboutAuthor;
