// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

import '../divi-blocks/about author/index.js'; import '../divi-blocks/blog post loop/index.js'; import '../divi-blocks/fixed button/index.js'; import '../divi-blocks/hero-slider/index.js'; import '../divi-blocks/map-location/index.js'; import '../divi-blocks/message form/index.js'; import '../divi-blocks/post loop slider/index.js'; import '../divi-blocks/post meta/index.js'; import '../divi-blocks/projects-slider/index.js'; import '../divi-blocks/subscribe form/index.js'; // eslint-disable-line
/* global google */

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Sticky footer
 */
// function stickyFooter() {
//   let footer = $('footer');
//   let position = footer.position();
//   let height = $(window).height();
//   height = height - position.top;
//   height = height - footer.outerHeight();
//   if (height > 0) {
//     footer.css({ 'margin-top': height + 'px' });
//   }
// }

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  //$('.gallery').attr('data-fancybox', '');
  $('.leadership-item').on('click', function () {
    let a = $(this).find('.popup');
    $.fancybox.open(a);
  });

  $('.back-leaderships').on('click', function (event) {
    event.preventDefault();
    $.fancybox.close();
  });

  $('.post-content')
    .find(
      'a[rel*="album"], .fancybox, a[href$="jpg"], a[href$="png"], a[href$="gif"]'
    )
    .fancybox({
      minHeight: 0,
      helpers: {
        overlay: {
          locked: false,
        },
      },
    });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  // $(document).on('gform_confirmation_loaded', function (event, formId) {
  //   let $target = $('#gform_confirmation_wrapper_' + formId);
  //   if ($target.length) {
  //     $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
  //     return false;
  //   }
  // });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
  //stickyFooter();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  /*
   *  This function will render each map when the document is ready (page has loaded)
   */
  $('.acf-map').each(function () {
    render_map($(this));
  });

  $('#mobile_menu2')
    .find('li')
    .has('ul')
    .prepend(
      '<span class="menu-arrow" ><i class="fas fa-angle-down"></i></span>'
    );

  $(document).on('click', '.menu-arrow', function () {
    $(this).siblings('ul').toggleClass('visibility');
  });
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  resizeVideo();
  //stickyFooter();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});

/*
 *  This function will render a Google Map onto the selected jQuery element
 */

function render_map($el) {
  // var
  var $markers = $el.find('.marker');
  // var styles = []; // Uncomment for map styling

  // vars
  var args = {
    zoom: 16,
    center: new google.maps.LatLng(0, 0),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    // styles : styles // Uncomment for map styling
  };

  // create map
  var map = new google.maps.Map($el[0], args);

  // add a markers reference
  map.markers = [];

  // add markers
  $markers.each(function () {
    add_marker($(this), map);
  });

  // center map
  center_map(map);
}

/*
 *  This function will add a marker to the selected Google Map
 */

var infowindow;

function add_marker($marker, map) {
  // var
  var latlng = new google.maps.LatLng(
    $marker.attr('data-lat'),
    $marker.attr('data-lng')
  );

  // create marker
  var marker = new google.maps.Marker({
    position: latlng,
    map: map,
    //icon: $marker.data('marker-icon') //uncomment if you use custom marker
  });

  // add to array
  map.markers.push(marker);

  // if marker contains HTML, add it to an infoWindow
  if ($.trim($marker.html())) {
    // create info window
    infowindow = new google.maps.InfoWindow();

    // show info window when marker is clicked
    google.maps.event.addListener(marker, 'click', function () {
      // Close previously opened infowindow, fill with new content and open it
      infowindow.close();
      infowindow.setContent($marker.html());
      infowindow.open(map, marker);
    });
  }
}

/*
 *  This function will center the map, showing all markers attached to this map
 */

function center_map(map) {
  // vars
  var bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  $.each(map.markers, function (i, marker) {
    var latlng = new google.maps.LatLng(
      marker.position.lat(),
      marker.position.lng()
    );
    bounds.extend(latlng);
  });

  // only 1 marker?
  if (map.markers.length == 1) {
    // set center of map
    map.setCenter(bounds.getCenter());
  } else {
    // fit to bounds
    map.fitBounds(bounds);
  }
}
