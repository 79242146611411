// Internal Dependencies
import HeroSlider from './hero-slider';

$(window).on('et_builder_api_ready', (event, API) => {
  API.registerModules([HeroSlider]);
});

$(document).on('ready', function () {
  $('.hero-slider').slick({
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 980,
        settings: {
          arrows: false,
        },
      },
    ],
  });
});
