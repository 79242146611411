// External Dependencies
import React from 'react';

class MapLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postArray: [],
    };
  }
  componentDidMount() {
    fetch(`/wp-json/wp/v2/location/${window.ETBuilderBackend.postId}`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok && response.status === 200) {
          return response.json();
        }
        throw new Error(response.statusText);
      })
      .then((response) => {
        this.setState({
          postArray: response,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error); //eslint-disable-line
        }
      });
  }
  render() {
    return this.state.postArray.acf ? (
      <div>
        <div className="acf-map">
          <div
            className="marker"
            data-lat={this.state.postArray.acf.location.lat}
            data-lng={this.state.postArray.acf.location.lng}
          ></div>
        </div>
        <div className="map-content">
          <div>
            <a
              href={`https://www.google.com/maps/dir/Current+Location/${this.state.postArray.acf.location.lat},${this.state.postArray.acf.location.lng}`}
              target="_blank"
              className="button button-green button-direct"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </div>
          <div
            className="location-contact"
            dangerouslySetInnerHTML={{
              __html: this.state.postArray.acf.short_info,
            }}
          ></div>
        </div>
      </div>
    ) : (
      ''
    );
  }
}

MapLocation.slug = 'fwp_map_location';

export default MapLocation;
