$('.projects-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  fade: true,
  dots: false,
  appendDots: $('.projects-slider__dots'),
  row: 0,
  asNavFor: '.second-slider',
});
$('.second-slider').slick({
  slidesToShow: 9,
  slidesToScroll: 1,
  asNavFor: '.projects-slider',
  dots: false,
  arrows: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 7,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
});
