// External Dependencies
import React from 'react';

class FixedButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonArray: [],
    };
  }
  componentDidMount() {
    fetch(`/wp-json/acf/v2/options/contact_button`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok && response.status === 200) {
          return response.json();
        }
        throw new Error(response.statusText);
      })
      .then((response) => {
        this.setState({
          buttonArray: response,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error); //eslint-disable-line
        }
      });
  }
  render() {
    return this.state.buttonArray.contact_button ? (
      <a
        className="contact-button"
        href={this.state.buttonArray.contact_button.url}
        target={this.state.buttonArray.contact_button.target}
      >
        {this.state.buttonArray.contact_button.title}
      </a>
    ) : (
      ''
    );
  }
}

FixedButton.slug = 'fwp_fixed_button';

export default FixedButton;
