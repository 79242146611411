// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

class MessageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: '',
      isLoaded: false,
    };
  }

  componentDidMount() {
    let str = `/wp-json/messageform/v1/message_form/`;

    fetch(str, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok && response.status === 200) {
          return response.json();
        }
        throw new Error(response.statusText);
      })
      .then((response) => {
        this.setState({
          isLoaded: true,
          form: response,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error); //eslint-disable-line
        }
      });
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.state.form }} />;
  }
}

MessageForm.propTypes = {
  title: PropTypes.string,
};

MessageForm.slug = 'fwp_message_form';

export default MessageForm;
