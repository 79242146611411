// External Dependencies
import React from 'react';
import Slider from 'react-slick';

class HeroSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderArray: [],
    };
  }
  componentDidMount() {
    fetch(`/wp-json/wp/v2/slider?_embed`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok && response.status === 200) {
          return response.json();
        }
        throw new Error(response.statusText);
      })
      .then((response) => {
        this.setState({
          sliderArray: response,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error); //eslint-disable-line
        }
      });
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      responsive: [
        {
          breakpoint: 980,
          settings: {
            arrows: false,
          },
        },
      ],
    };

    return (
      <Slider className="hero-slider" {...settings}>
        {this.state.sliderArray.map((one, key) => (
          <div key={key}>
            <div
              className="hero-slider__item"
              style={{
                backgroundImage: `url(${
                  one._embedded['wp:featuredmedia'] &&
                  one._embedded['wp:featuredmedia']['0'].link
                })`,
              }}
            >
              <div className="hero-slider__content">
                <h1 className="hero-slider__title">{one.title.rendered}</h1>
                <div
                  dangerouslySetInnerHTML={{ __html: one.content.rendered }}
                />
                <div className="box-button">
                  {one.acf['button1'] && (
                    <a
                      className="button button-green"
                      href={one.acf['button1']['url']}
                    >
                      {one.acf['button1']['title']}
                    </a>
                  )}
                  {one.acf['button2'] && (
                    <a
                      className="button button-blue"
                      href={one.acf['button2']['url']}
                    >
                      {one.acf['button2']['title']}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}

HeroSlider.slug = 'fwp_hero_slider';

export default HeroSlider;
