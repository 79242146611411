// External Dependencies
import React from 'react';

class PostMeta extends React.Component {
  render() {
    return (
      <div>
        <div className="post-meta-social">
          <p className="post-tags">
            <i className="fas fa-tags"></i>Post Tags
          </p>
          <p className="social-links">
            {`Share this article:`}
            <a href="#">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="#">
              <i className="fab fa-twitter-square"></i>
            </a>
            <a href="#">
              <i className="fas fa-envelope-square"></i>
            </a>
          </p>
        </div>
      </div>
    );
  }
}

PostMeta.slug = 'fwp_post_meta';

export default PostMeta;
