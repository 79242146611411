// External Dependencies
import React from 'react';
import Slider from 'react-slick';

class PostsSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postsArray: [],
    };
  }
  componentDidMount() {
    fetch(`/wp-json/wp/v2/posts?_embed`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok && response.status === 200) {
          return response.json();
        }
        throw new Error(response.statusText);
      })
      .then((response) => {
        this.setState({
          postsArray: response,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error); //eslint-disable-line
        }
      });
  }
  render() {
    const settings = {
      dots: false,
      arrows: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    };

    return (
      <div>
        <Slider className="post-slider" {...settings}>
          {this.state.postsArray.map((one, key) => (
            <article key={key} className="post-item">
              <a className="post-link" href={one.link}>
                {one._embedded['wp:featuredmedia'] && (
                  <img
                    src={one._embedded['wp:featuredmedia']['0'].source_url}
                  />
                )}
              </a>
              <div className="post-info">
                <a className="post-link" href={one.link}>
                  <h5 className="post-title">{one.title.rendered}</h5>
                </a>
                <div className="post-date">{one.date}</div>
                <div className="post-text">
                  <div
                    dangerouslySetInnerHTML={{ __html: one.excerpt.rendered }}
                  />
                  <div className="post-end">
                    <a className="post-link" href="{one.link}">
                      Read More
                    </a>
                    <p className="post-category">
                      <a href={one._embedded['wp:term']['0']['0'].link}>
                        {one._embedded['wp:term']['0']['0'].name}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </article>
          ))}
        </Slider>
      </div>
    );
  }
}

PostsSlider.slug = 'fwp_loop_post_slider';

export default PostsSlider;
